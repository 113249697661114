import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyAOlSbv6Z4JeMs8PjHz0I72P1gPZieacQU",
  authDomain: "taunus-24aa9.firebaseapp.com",
  projectId: "taunus-24aa9",
  storageBucket: "taunus-24aa9.appspot.com",
  messagingSenderId: "301284437516",
  appId: "1:301284437516:web:3389f144bb2e01cab08829",
};

export default firebaseConfig;
